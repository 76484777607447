.slider-control {
  position: absolute;
  bottom: 40px;
  width: 90%;
  margin: auto;
  right: 0px;
  left: 0;
}

.slider-control-input {
  max-width: 50px;
}

.slider-control-block {
  display: inline-block;
}

.slider-control-range {
  width: 100%;
}

.slider-control-player {
  position: absolute;
  height: 24px;
}

.player-button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 24px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)
    rgba(0, 0, 0, 0.7);
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 12px 0 12px 24px;
  vertical-align: middle;
  margin: 0 10px;
}

.player-button:focus {
  outline: none;
}

.player-button.paused {
  border-style: double;
  border-width: 0 0 0 24px;
}

.player-button:hover {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)
    rgba(0, 0, 0, 1);
}

.slider-control-steps {
  position: absolute;
  bottom: -50%;
  right: 0;
  height: 24px;
}

.slider-control-steps-btn {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  outline: none;
  opacity: 0.7;
  vertical-align: middle;
  cursor: pointer;
}

.slider-control-steps-btn.disabled {
  cursor: default;
  opacity: 0.3;
}

.slider-control-steps-btn.next {
  background-image: url(../img/next.svg);
}
.slider-control-steps-btn.previous {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23333333;' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E");
}

.slider-control-steps-btn:hover {
  opacity: 1;
}
